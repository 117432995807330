<template>
  <div class="page-content">
    <page-breadcrumb title="Gift Category" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="category">
        <form-generator :model="category" :schema="category_form_schema" />
      </b-card>
    </section>

    <section class="mb-2" v-if="category">
      <h2 class="mb-2">Gifts In This Category</h2>
      <data-table-ssr id="gift_list" ref="gift_list" :limit-base="36" :pagination="false"
        :columns="gift_fields" :get-list-fn="getGifts"
        :edit-row-fn="editGift" :delete-row-fn="deleteGift"
      />
      <div class="d-flex justify-content-center my-2">
        <data-create-popup title="Create Gift" :schema="gift_create_schema"
          :default-data="{ name: null, product_code: null, crm_price: null, crm_price_refer: null, thumbnail: null }"
          :create-data-fn="createGift" @create="reloadGiftList"
        />
      </div>
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service_category';
import giftService from '../service';

const category_form_schema = [
  {
    cols: 4,
    fields: [
      { label: 'Thumb', field: 'thumbnail', input_type: 'image'  },
    ]
  },
  {
    cols: 8,
    fields: [
      { label: 'Name', field: 'name', input_type: 'text', validate: { required: true }  },
      { label: 'Price', field: 'price', input_type: 'number',  validate: { type: 'number', min: 0 }  },
    ]
  }
];

const gift_fields = [
  { label: 'ID', field: 'id' },
  { label: 'Thumb', field: 'thumbnail', input_type: 'image', editable: true },
  { label: 'Name', field: 'name', editable: true },
  { label: 'Product Code', field: 'product_code', editable: true },
  { label: 'Price Original', field: 'crm_price_refer', input_type: 'number', editable: true },
  { label: 'Price After Sale', field: 'crm_price', input_type: 'number', editable: true },
  { label: 'Created At', field: 'insert_datetime', input_type: 'datetime' },
];
const gift_create_schema = [
  {
    cols: 4,
    fields: [{ label: 'Thumb', field: 'thumbnail', input_type: 'image' }],
  },
  {
    cols: 8,
    fields: [
      { label: 'Name', field: 'name', input_type: 'text', validate: { required: true }  },
      { label: 'Product Code', field: 'product_code', input_type: 'text', validate: { required: true }  },
      { label: 'Price', field: 'crm_price', input_type: 'number',  validate: { type: 'number', min: 0 }  },
      { label: 'Price Refer', field: 'crm_price_refer', input_type: 'number',  validate: { type: 'number', min: 0 }  },
    ],
  }
];

export default {
  data(){
    return{
      category_form_schema,
      gift_fields,
      gift_create_schema,
      category: null,
      isUpdating: false,
      isDeleting: false,
      gifts: null,
    }
  },
  computed: {
    productId() {
      return this.$route.params.category_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Gift Categories',
          to: { name: 'earn-gift-categories' },
        },
        {
          text: `${this.productId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getCategory();
  },
  methods:{
    // category
    async getCategory(){
      this.category = await service.get({id: this.productId});
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.category),
      });
      this.getCategory();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.productId });
      this.$router.push({ name: "earn-gift-categories" });
    },
    // gift
    async createGift(data) {
      let new_item = await giftService.create({ ...data, category_id: this.category.id, language: this.authLanguage });
      return new_item;
    },
    async getGifts({ limit, page, query, searchTerm }){
      let data = await giftService.getList({
        query: JSON.stringify({ category_id: this.productId }),
      });
      let list = [], total = 0;
      if(data) {
        list = data.list;
        total = data.total;
      }
      return { list, total };
    },
    reloadGiftList() {
      this.$refs.gift_list.getList();
    },
    async editGift(row) {
      await giftService.update({ data: JSON.stringify(row) });
    },
    async deleteGift({ id }) {
      await giftService.delete({ id });
      this.reloadGiftList();
    },
  }
}
</script>
